<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template><div>
	<h3>Satchel Publishing Options for Issuing Agencies</h3>

	<p><a href="https://commongoodlt.com/" target="_blank">Common Good Learning Tools</a> (CGLT) is a not-for-profit company that exists to serve educators and students. One of the tools we build, support, and are constantly enhancing is Standards Satchel, a system for authoring, managing, and publishing learning standards using the CASE specification for machine-readable competencies. {{extra_phrase}}</p>
		
	<p>CGLT offers two Satchel hosting options for public agencies that issue learning standards:</p>
	<ul>
		<li><b>Dedicated Hosting</b>: With this option, the issuing agency has a dedicated Satchel server instance for the agency’s exclusive use.</li>
		<li><b>Shared Hosting</b>: To provide a lower-cost alternative, CGLT maintains a shared-tenancy Satchel instance called Satchel Commons where we can host frameworks for any issuing agency.</li>
	</ul>
	<p>Please see this <a href="https://docs.google.com/document/d/1NyqvckxrpV_7RE-SP_93x08k3jDlhfNtr9ofH0iM23o" target="_blank">document</a> for more information, including a table that explains the key difference and similarities between these two options.</p>

</div></template>

<script>
	import HelpMixin from './HelpMixin'
	export default {
		mixins: [HelpMixin],
		computed: {
			extra_phrase() {
				if (['Standards Satchel', 'Satchel Commons'].includes(this.$store.state.site_config.app_name)) return ' You’re currently viewing Standards Satchel right now!'
				if (this.$store.state.site_config.app_name == 'CASE Network 2') return ' You’re currently viewing CASE Network 2, an instance of Standards Satchel hosted by CGLT in collaboration with 1EdTech.'
				return ` You’re currently viewing ${this.$store.state.site_config.app_name}, a branded instance of Standards Satchel.`
			},
		}
	}
</script>

<style lang="scss">
</style>
	